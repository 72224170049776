<template>

    <div class="flex h-full items-center mb-4" v-if="branch.available_contacts.length > 0">
        <Radio radioName="selectedStatus" v-model="existingContact" :items="statuses" @change="clearContact"/>
    </div>

    <form v-if="!existingContact" @submit.prevent="submit" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1">
                <Input placeholder="Vārds Uzvārds" v-model="form.name" :errors="errors.name"/>
            </div>

            <div class="col-span-1">
                <Input placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone"/>
            </div>

            <div class="col-span-1">
                <Input placeholder="E-pasts" v-model="form.email" :errors="errors.email"/>
            </div>

            <div class="col-span-1">
                <Input placeholder="Nodarbošanās" v-model="form.occupation" :errors="errors.occupation"/>
            </div>

            <div class="col-span-1">
                <Input type="date" placeholder="Dzimšanas diena" v-model="form.birth_date" :errors="errors.birth_date"/>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button @click="hideAddForm" type="button"
                        class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                    Atcelt
                </button>

                <template v-if="!loading">
                    <button type="button"
                            class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                            @click.prevent="submit">
                        Saglabāt
                    </button>
                </template>
                <template v-else>
                    <Loading/>
                </template>
            </div>
        </div>
    </form>

    <form v-if="existingContact" @submit.prevent="attachContact" class="space-y-6 w-full divide-y divide-gray-200 dark:divide-gray-700">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1">
                <Select placeholder="Klienta kontakti" v-model="contact"  :items="branch.available_contacts"/>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button @click="hideAddForm" type="button"
                        class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                    Atcelt
                </button>

                <template v-if="!loading && contact">
                    <button type="button"
                            class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                            @click.prevent="attachContact">
                        Saglabāt
                    </button>
                </template>
                <template v-if="loading">
                    <Loading/>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Radio from "../../../../Components/Radio";
import Select from "../../../../Components/Select";

export default {
    name: "AddNewBranchContact",
    components: {
        Select,
        Input,
        Loading,
        Radio
    },
    props: ["branch"],
    data: () => ({
        existingContact: false,
        statuses: [
            {
                name: 'Jauns kontakts',
                value: 0
            },
            {
                name: 'Esošs kontakts',
                value: 1
            },
        ],
        form: {
            name: "",
            phone: "",
            email: "",
            occupation: "",
            birth_date: "",
        },
        contact: null
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                name: {
                    rules: ['required']
                },
                email: {
                    rules: ['email']
                },
            }
        }
    },
    methods: {
        clearContact() {
            this.contact = null
        },
        hideAddForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("createNewBranchContact", {
                    data: {
                        name: this.form.name,
                        phone: this.form.phone,
                        email: this.form.email,
                        occupation: this.form.occupation,
                        birth_date: this.form.birth_date,
                    },
                    branchId: this.branch.id,
                });
            } else {
                this.$Progress.fail();
            }
        },
        attachContact() {
            this.$Progress.start();

            this.$store.dispatch("attachExistingBranchContact", {
                    contactId: this.contact.id,
                    branchId: this.branch.id,
                });
        }
    },
}
</script>
