<template>
  <form v-if="form" class="space-y-6 w-full">

    <div class="grid grid-cols-2 gap-6 pt-4">
      <div class="col-span-1">
        <Input name="name" placeholder="Vārds, Uzvārds" v-model="form.name" :errors="errors.name" />
      </div>

      <div class="col-span-1">
        <Input name="phone" placeholder="Telefona nr." v-model="form.phone" :errors="errors.phone" />
      </div>

      <div class="col-span-1">
        <Input name="email" placeholder="E-pasts" v-model="form.email" :errors="errors.email" />
      </div>
    </div>

    <div class="pt-2">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideEditForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Input from "@/components/Components/Input";

export default {
  name: "EditCustomerContact",
  components: {
    Loading,
    Input,
  },
  data: () => ({
    form: null
  }),
  props: {
    contact: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.form = this.contact
  },
  computed: {
    ...mapGetters({
      branch: "branch",
      contact: "editableBranchContact",
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        email: {
          rules: ['email']
        },
      }
    }
  },
  methods: {
    hideEditForm() {
      this.$store.dispatch("getSingleBranch", this.branch.id);
      this.$store.dispatch("clearEditableBranchContact");
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateBranchContact", {
          data: {
            id: this.contact.id,
            name: this.form.name,
            phone: this.form.phone,
            email: this.form.email,
          },
          branchId: this.branch.id,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>