<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Filiāles kontaktpersonas
      </h3>
    </template>
    <template v-slot:buttons>

      <template v-if="this.$userCan('manage_customers')">
        <Button icon="plus" @click="showForm('AddBranchContact')" />
      </template>

    </template>
    <template v-slot:content>

        <template v-if="!displayForm('AddBranchContact')">

            <template v-if="contacts.length > 0">
              <template v-for="contact in contacts" :key="contact.id">
                <ShowBranchContact :contact="contact"/>
              </template>
            </template>

            <template v-else>
              <div class="w-full">
                <p class="text-center text-lg text-gray-400 mb-3">Filiālei nav pievienoti kontakti</p>
                <button @click="showForm('AddBranchContact') && this.$userCan('manage_customers')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
                  Pievienot
                </button>
              </div>
            </template>
        </template>

        <template v-else>
          <add-new-branch-contact v-if="displayForm('AddBranchContact') && this.$userCan('manage_customers')" :branch="branch"/>
        </template>

    </template>

  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import ShowBranchContact from "@/components/Customer/Branches/Branch/BranchContacts/ShowBranchContact"
import AddNewBranchContact from "@/components/Customer/Branches/Branch/BranchContacts/AddNewBranchContact"

export default {
  name: "AllBranchContacts",
  components: {
    ItemCard,
    ShowBranchContact,
    AddNewBranchContact,
  },
  props: {
    contacts: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.$store.dispatch('clearEditableCustomerContact')
  },
  computed: {
    ...mapGetters({
      branch: 'branch',
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>