<template>
  <template v-if="editableBranchContact.id !== contact.id">
    <div class="block hover:bg-gray-100 dark:hover:bg-gray-770 w-full rounded-lg">
      <div class="py-4">
        <div class="sm:flex flex-wrap lg:flex-nowrap px-2 items-center">

          <router-link :to="`/contacts/${contact.id}`" class="grid grid-cols-12 flex-1">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3">
              <ItemText :text="contact.name" />
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
              <ItemText :text="contact.phone" />
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3">
              <ItemText :text="contact.email" />
            </div>
          </router-link>


          <div class="ml-auto">
            <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">

              <!--  Edit button-->
              <template v-if="this.$userCan('manage_customers')">
                <Button icon="pencil" @click="editContact" />
              </template>

              <!--  Delete button-->
              <template v-if="this.$userCan('manage_customers')">
                <Button icon="delete" @click="deleteBranchContact(contact.id)" />
              </template>
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else>
    <EditBranchContact :contact="contact" />
  </template>

</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"
import EditBranchContact from "@/components/Customer/Branches/Branch/BranchContacts/EditBranchContact"

export default {
  name: "ShowBranchContact",
  components: {
    ItemText,
    EditBranchContact,
  },
  props: ['contact'],
  computed: {
    ...mapGetters({
      branch: 'branch',
      editableBranchContact: 'editableBranchContact'
    })
  },
  methods: {
    inviteUserToContact(){
      this.$Progress.start()
      this.$store.dispatch('inviteUserToContact', {
        contactId: this.contact.id,
        branchId: this.branch.id
      })
    },
    revokeUserFromContact(){
      this.$Progress.start()
      this.$store.dispatch('revokeUserFromContact', {
        contactId: this.contact.id,
        branchId: this.branch.id
      })
    },
    editContact(){
      this.$store.dispatch('setEditableBranchContact', this.contact)
    },
    deleteBranchContact(contactId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteBranchContact', {
          contactId: contactId,
          branchId: this.branch.id
        })
      }
    }
  }
}
</script>

<style>
</style>